<template>
    <div class="tags" v-if="tagsList.length">
        <ul>
            <draggable v-model="tagsList" group="people" animation="500">
                <transition-group
                    enter-active-class="animate__animated animate__fadeIn"
                    leave-active-class="animate__animated animate__hinge"
                    style="display:flex;flex-wrap: wrap;"
                >
                    <li
                        class="tags-li"
                        v-for="(item, index) in tagsList"
                        :class="{ active: isActive(item.path) }"
                        :key="item.path"
                        @click="push(item.path)"
                    >
                        <router-link :to="item.path" class="tags-li-title">{{ item.title }}</router-link>
                        <span class="tags-li-icon" @click="closeTags($event, index)">
                            <i class="el-icon-close"></i>
                        </span>
                    </li>
                </transition-group>
            </draggable>
        </ul>
    </div>
</template>

<script>
import draggable from "vuedraggable";
export default {
    data() {
        return {
            showTags: true,
            tagsList: [],
        };
    },
    mounted() {
        if (sessionStorage.getItem("taglist")) {
            this.tagsList = JSON.parse(sessionStorage.getItem("taglist"));
        }
    },
    methods: {
        name() {
            console.log();
        },
        isActive(val) {
            if (val == this.$route.path) {
                return true;
            } else {
                return false;
            }
        },
        push(val) {
            this.$emit("acatvePath", val);
        },
        closeTags(e, i) {
            e.stopPropagation();
            if (this.tagsList[i].path == this.$route.path) {
                if (this.tagsList.length == 1) {
                    this.$router.push("/home");
                    this.tagsList.splice(i, 1);
                    sessionStorage.setItem("taglist", JSON.stringify(this.tagsList));
                    return;
                }
                if (i > 0) {
                    this.$router.push(this.tagsList[i - 1].path);
                    this.$emit("acatvePath", this.tagsList[i - 1].path);
                } else {
                    this.$router.push(this.tagsList[i + 1].path);
                    this.$emit("acatvePath", this.tagsList[i + 1].path);
                }
            }
            this.tagsList.splice(i, 1);
            sessionStorage.setItem("taglist", JSON.stringify(this.tagsList));
        },
    },
    components: {
        draggable,
    },
};
</script>

<style>
.tags {
    position: relative;
    /* height: 60px; */

    background: #fff;
    padding: 5px;
    /* width: 100%; */

    padding-right: 120px;
    box-shadow: 0 5px 10px #ddd;
    margin-bottom: 10px;
    z-index: 2;
}

.tags ul {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
}

.tags-li {
    /* float: left; */

    margin: 3px 5px 2px 3px;
    border-radius: 3px;
    font-size: 12px;
    overflow: hidden;
    cursor: pointer;
    height: 23px;
    line-height: 23px;
    border: 1px solid #e9eaec;
    background: #fff;
    padding: 0 5px 0 12px;
    vertical-align: middle;
    color: #666;
    transition: 0.3;
    /* -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in; */
    /* transition: all 0.3s ease-in; */
}

.tags-li:not(.active):hover {
    background: #f8f8f8;
}

.tags-li.active {
    color: #fff;
    background-color: #409eff;
}

.tags-li-title {
    float: left;
    max-width: 80px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 5px;
    color: #666;
}

.tags-li.active .tags-li-title {
    color: #fff;
}

.tags-close-box {
    position: absolute;
    right: 0;
    top: 0;
    box-sizing: border-box;
    padding-top: 1px;
    text-align: center;
    width: 110px;
    height: 30px;
    background: #fff;
    box-shadow: -3px 0 15px 3px rgba(0, 0, 0, 0.1);
    z-index: 10;
}
</style>
