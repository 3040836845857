<template>
    <div class="item">
        <i class="el-icon-bell" style="font-size:16px;color:#fff"></i>
        <div class="box-click" @click.stop="show = !show"></div>
        <el-badge :value="total" :max="99" class="total-item" v-if="total"> </el-badge>
        <div class="box" v-show="show">
            <div class="box-item" v-for="(item, index) in tab" :key="index" @click="skipTo(item)">
                {{ item.name }}
                <el-badge v-if="item.num" :value="item.num" :max="99" class="el-item"> </el-badge>
            </div>
        </div>
    </div>
</template>

<script>
import VueCookies from "vue-cookies";
export default {
    inject: ["saveNavstate"],
    data() {
        return {
            tab: {
                userCheck: {
                    num: "",
                    name: "会员认证待审核",
                    path: "/user_cert_list",
                    realName: "职业认证审核",
                },
                cooperationCheck: {
                    name: "合作待审核",
                    path: "/cooperation_cert_list",
                    realName: "合作审核",
                },
                enterpriseCertCheck: {
                    name: "企业认证待审核",
                    path: "/enterprise_cert_list",
                    realName: "企业认证审核",
                },
                enterpriseEditCheck: {
                    name: "企业信息待审核",
                    path: "/user_enterprise_edit_check",
                    realName: "企业信息审核",
                },
                enterpriseArticleCheck: {
                    name: "企业文章待审核",
                    path: "/enterprise_article_check_list",
                    realName: "企业文章审核",
                },
                enterpriseCaseCheck: {
                    name: "企业案例待审核",
                    path: "/enterprise_case_check_list",
                    realName: "企业案例审核",
                },
                orderCheck: {
                    name: "发票申请",
                    path: "/order_list?invoiceStatus=1",
                    realName: "订单列表",
                },
                mallOrderCheck: {
                    name: "积分商城待发货",
                    path: "/product_record?isSend=0",
                    realName: "积分商城",
                },

                rotaryCheck: {
                    name: "抽奖奖品待发货",
                    path: "/rotary_list_data?isSend=4",
                    realName: "幸运大抽奖",
                },
                emailCheck: {
                    name: "邮件群发待执行",
                    path: "/email_task_list",
                    realName: "邮件群发",
                },
            },
            show: false,
            total: 0,
            timeout: null,
        };
    },
    methods: {
        async getCheckInfo() {
            const { data: res } = await this.$http.get("/admin/Index/getCheckInfo");
            this.total=res.data.total
            this.tab=res.data.menu.map(item => {
              item.path = '/'+item.url
              return item
            })
            for (const key in res.data) {
                if (key == "total") {
                    this.total = res.data[key];
                    continue;
                }
                // this.tab[key].num = res.data[key];
            }
        },
        skipTo(val) {
            // console.log(val);
            if (location.hash == val.path) return;
            this.saveNavstate(val.path, val.realName);
            this.$router.push(val.path);
        },
    },
    mounted() {
        clearInterval(this.timeout);
        this.timeout = setInterval(async () => {
            if (!VueCookies.get("token")) {
                clearInterval(this.timeout);
                return;
            }
            this.getCheckInfo();
        }, 60000);
        this.getCheckInfo();
        document.onclick = () => {
            this.show = false;
        };
    },
};
</script>

<style lang="less" scoped>
.item {
    position: relative;
    z-index: 9;
    left: -5px;
    .box-click {
        position: absolute;
        width: 45px;
        height: 40px;
        left: -12px;
        top: -8px;
    }
    .total-item {
        position: absolute;
        right: -19px;
        top: -6px;
        pointer-events: none;
        /deep/.el-badge__content {
            transform: scale(0.84);
            background-color: #ef3435;
            border: none;
        }
    }
    .box {
        position: absolute;
        box-sizing: border-box;
        top: 20px;
        left: -129px;
        background-image: url(https://static-cdn.yfchuhai.com/www.yfchuhai.com/static/pc/img/enterprise/web_bg.png);
        background-size: 100% 100%;
        width: 206px;
        // height: 329px;
        padding: 40px 27px 20px;
        color: #333;
        .box-item {
            font-size: 14px;
            margin-bottom: 11px;
            //   overflow: hidden;
            position: relative;
            cursor: pointer;
            .el-item {
                position: absolute;
                right: 0;
                /deep/.el-badge__content {
                    transform: scale(0.84) translateX(5px);
                    background-color: #ef3435;
                }
            }
            &:not(:nth-child(1)) {
                // margin-top: 15px;
                padding-top: 11px;
                border-top: 1px solid #e5e5e5;
            }
        }
    }
}
</style>
